import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import {convertDateAndTimeToView, convertDateToView} from "../../utils/SharedFunctions";
export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'First Name',
		Footer : 'First Name',
		accessor: 'first_name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Last Name',
		Footer : 'Last Name',
		accessor: 'last_name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Email Id',
		Footer : 'Email Id',
		accessor: 'email',
		Filter: ColumnFilter,
	},
	{
		Header : 'Date of  Birth',
		Footer : 'Date of  Birth',
		accessor: 'date_of_birth',
		Cell: ({ value }) => {return format(new Date(value), 'dd/mm/yyyy')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Country',
		Footer : 'Country',
		accessor: 'country',
		Filter: ColumnFilter,
	},
	{
		Header : 'Phone',
		Footer : 'Phone',
		accessor: 'phone',
		Filter: ColumnFilter,
	},
]



export const companies_col = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
	},
	{
		Header : 'Name',
		Footer : 'Name',
		accessor: 'name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Email',
		Footer : 'Email',
		accessor: 'email',
		Filter: ColumnFilter,
	},

	{
		Header : 'Phone',
		Footer : 'Phone',
		accessor: 'phone',
		Filter: ColumnFilter,
	},
	{
		Header : 'Website',
		Footer : 'Website',
		accessor: 'website',
		Filter: ColumnFilter,
	},
	{
		Header : 'Date',
		Footer : 'Date',
		accessor: 'created_at',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return  convertDateAndTimeToView(value)},
	},
]
