import {apiGet, apiPost, apiPut} from "./Api";
import {appUrls} from "../config/Constants";
import {loginAgain, LoginAgain} from "../utils/SharedFunctions";


export async function step1AuthCall(data) {
    return apiPost(appUrls.backend_api+"request_code", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function step2AuthCall(data) {
    return apiPost(appUrls.backend_api+"auth_based_on_code", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}




export async function getAllCompaniesCall() {
    return apiGet(appUrls.backend_api+"get_all_companies").then(result => {
        return result;
    }).catch(err => {
        loginAgain(err)
        throw err;
    });
}

export async function updateCompanyInfoCall(data) {
    return apiPut(appUrls.backend_api+"update_company", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function addNewCompanyCall(data) {
    return apiPost(appUrls.backend_api+"add_company", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function deleteCompanyCall(data) {
    return apiPost(appUrls.backend_api+"delete_company", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

