import React, {useEffect, useMemo, useState} from 'react';
import {useTable, useGlobalFilter, useSortBy, useFilters, usePagination} from 'react-table';
import '../../../components/FilteringTable/filtering.css';

import {toast} from "react-toastify";
import {Modal} from "react-bootstrap";

import {companies_col, orders_col} from "../../../components/FilteringTable/Columns";
import {GlobalFilter} from "../../../components/FilteringTable/GlobalFilter";
import {appToastMessages, toastOptions} from "../../../config/Constants";
import {
    addNewCompanyCall, deleteCompanyCall,
    getAllCompaniesCall,
    getAllOrderCall,
    getOrderStickerCall, updateCompanyInfoCall,
    updateOrderDeliveryLinkCall,
    updateOrderStatusCall
} from "../../../Api/ApiCalls";
import {convertDateAndTimeToView, hasAValue} from "../../../utils/SharedFunctions";
import Swal from "sweetalert";


export default function CompaniesOverview() {
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [editable, setEditable] = useState(false)


    const [item, setItem] = useState(undefined)
    const [name, setName] = useState(undefined)
    const [email, setEmail] = useState(undefined)
    const [phone, setPhone] = useState(undefined)
    const [website, setWebsite] = useState(undefined)


    useEffect(() => {
        setColumns(companies_col)
        getAllCompanies()
    }, [])


    const tableInstance = useTable({
        columns,
        data,
        initialState: {pageIndex: 0}
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const {globalFilter, pageIndex} = state


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Orders</h4>
                    <a className="btn btn-secondary" onClick={() => openModalAdd()}>+ Add new company</a>

                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span className="ml-1">
                                                {column.isSorted ? (
                                                        column.isSortedDesc ?
                                                            <i className="fa fa-arrow-down ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                            :
                                                            <i className="fa fa-arrow-up ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                    )
                                                    :
                                                    (<i className="fa fa-sort ms-2 fs-14"
                                                        style={{opacity: '0.3'}}/>)}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>

                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className={"cursor-pointer"}
                                                       onClick={() => openModalEdit(row.original)}> {cell.render('Cell')} </td>
                                        })}

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {/* This is only for footer if u require */}
                        <div className="d-flex justify-content-between">
							<span>
								Page{' '}
                                <strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
                            <span className="table-index">
								Go to page : {' '}
                                <input type="number"
                                       className="ml-2"
                                       defaultValue={pageIndex + 1}
                                       onChange={e => {
                                           const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                           gotoPage(pageNumber)
                                       }}
                                />
							</span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button className=" previous-button" onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}>{'<<'}</button>

                                <button className="previous-button" onClick={() => previousPage()}
                                        disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                {hasAValue(item) && <h4 className="modal-title fs-20">Order number {item.id}</h4>}
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}
                                        data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">

                                {/*Customer info*/}
                                <div className="card border-top shadow">
                                    <div className="card-header border-0 pb-0">
                                        <h2 className="card-title">Company info</h2>
                                        <span
                                            className={"col-9 fw-bold text-primary ps-2"}>
                                                <input
                                                    type="checkbox"
                                                    checked={editable}
                                                    className={editable ? "form-check form-control-sm fw-bold text-primary" : "form-check bg-light form-control-sm fw-bold text-primary"}
                                                    required
                                                    onChange={() => setEditable(!editable)}
                                                /></span>
                                    </div>
                                    <div className="card-body pb-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Name</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}><input
                                                    className={editable ? "form-control form-control-sm fw-bold text-primary" : "bg-light form-control form-control-sm fw-bold"}
                                                    disabled={!editable}
                                                    defaultValue={name}
                                                    required
                                                    onChange={(val) => {
                                                        setName(val.target.value)
                                                    }}
                                                >
                                                </input></span>

                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Email </strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}><input
                                                    className={editable ? "form-control form-control-sm fw-bold text-primary" : "bg-light form-control form-control-sm fw-bold "}
                                                    disabled={!editable}
                                                    defaultValue={email}
                                                    required
                                                    onChange={(val) => {
                                                        setEmail(val.target.value)
                                                    }}
                                                >
                                                </input></span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Phone</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}><input
                                                    className={editable ? "form-control form-control-sm fw-bold text-primary" : "bg-light form-control form-control-sm fw-bold "}
                                                    disabled={!editable}
                                                    defaultValue={phone}
                                                    required
                                                    onChange={(val) => {
                                                        setPhone(val.target.value)
                                                    }}
                                                >
                                                </input></span>
                                            </li>


                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Website</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}><input
                                                    className={editable ? "form-control form-control-sm fw-bold text-primary" : "bg-light form-control form-control-sm fw-bold"}
                                                    disabled={!editable}
                                                    defaultValue={website}
                                                    required
                                                    onChange={(val) => {
                                                        setWebsite(val.target.value)
                                                    }}
                                                >
                                                </input></span>
                                            </li>

                                            {hasAValue(item) && <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Date</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{ convertDateAndTimeToView(item.created_at)}</span>
                                            </li>}

                                        </ul>
                                    </div>
                                </div>

                            </div>
                            {showEdit &&
                            <div className="modal-footer">
                                <button type="button" onClick={() => setShowModal(false)}
                                        className="btn btn-primary">Close
                                </button>
                                <button type="button" onClick={() => updateCompanyInfo(item)}
                                        className="btn btn-warning">Update
                                </button>
                                <button type="button" onClick={() => deleteCompany(item)}
                                        className="btn btn-danger">Delete
                                </button>
                            </div>}

                            {!showEdit &&
                            <div className="modal-footer">
                                <button type="button" onClick={() => setShowModal(false)}
                                        className="btn btn-primary">Close
                                </button>
                                <button type="button" onClick={() => addANewCompany()}
                                        className="btn btn-warning">Add
                                </button>
                            </div>
                            }


                        </form>

                    </div>
                </div>
            </Modal>
        </>
    )

    function openModalEdit(item) {
        setItem(item)
        setShowModal(true)
        setShowEdit(true)
        setName(item.name)
        setEmail(item.email)
        setPhone(item.phone)
        setWebsite(item.website)
        setEditable(false)
    }

    function openModalAdd(item) {
        setItem(item)
        setShowModal(true)
        setShowEdit(false)
        setName(undefined)
        setEmail(undefined)
        setPhone(undefined)
        setWebsite(undefined)
        setEditable(true)
    }


    function getAllCompanies() {
        getAllCompaniesCall().then(companies => {
            console.log(companies)
            setData(companies)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }

    function updateCompanyInfo(company) {
        Swal({
            title: "Are you sure?",
            text: "You want to update this company!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((response) => {
            if (response) {
                let data = {id: company.id, name, email, phone, website}
                updateCompanyInfoCall(data).then(res => {
                    getAllCompanies()
                    setShowModal(false)
                    toast.success(res.data.message, toastOptions);
                }).catch(error => {
                    toast.error(error.response.data.error, toastOptions);
                })
            } else {
                toast.error(appToastMessages.action_canceled, toastOptions);
            }
        })
    }

    function addANewCompany() {
        Swal({
            title: "Are you sure?",
            text: "You want to add this company!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((response) => {
            if (response) {
                let data = {name, email, phone, website}
                addNewCompanyCall(data).then(res => {
                    getAllCompanies()
                    setShowModal(false)
                    toast.success(res.data.message, toastOptions);
                }).catch(error => {
                    toast.error(error.response.data.error, toastOptions);
                })
            } else {
                toast.error(appToastMessages.action_canceled, toastOptions);
            }
        })
    }

    function deleteCompany(company) {
        Swal({
            title: "Are you sure?",
            text: "You want to add this company!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((response) => {
            if (response) {
                let data = {id: company.id}
                deleteCompanyCall(data).then(res => {
                    getAllCompanies()
                    setShowModal(false)
                    toast.success(res.data.message, toastOptions);
                }).catch(error => {
                    toast.error(error.response.data.error, toastOptions);
                })
            } else {
                toast.error(appToastMessages.action_canceled, toastOptions);
            }
        })
    }
}
